const MAX_CONTAINER_HEIGHT = 350
const MIN_CONTAINER_HEIGHT = 150

export class ImageResizerAndGridFilter {
  constructor() {
    this.needIncrementResizeScale = false
  }

  setNeedIncrementResizeScale(gridType) {
    this.needIncrementResizeScale = gridType == 'small' ? true : false
  }

  doWeNeedIncrementResizeScale = () => this.needIncrementResizeScale

  calculateHeightBasedOnInnerWidth(innerWidth) {
    switch(location.pathname) {
      case '/news':
        return this.doWeNeedIncrementResizeScale()
                ? innerWidth / (3 + 1)
                : innerWidth / 3
      default:
        return this.doWeNeedIncrementResizeScale()
                ? innerWidth / (4 + 1)
                : innerWidth / 4
    }
  }

  setHeightBasedOnInnerWidth(innerWidth) {
    let calculatedHeight = this.calculateHeightBasedOnInnerWidth(innerWidth)
    if (calculatedHeight > MAX_CONTAINER_HEIGHT) calculatedHeight = MAX_CONTAINER_HEIGHT


    if (calculatedHeight < MIN_CONTAINER_HEIGHT) return

    $('.grid-image').each(function() {
      $(this).css('height', calculatedHeight)
    })
  }

  filterSectionContent(gridType, event) {
    const animationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
    const gridItems = document.getElementsByClassName('grid-filter-item')
    const removableClass = gridType == 'small' ? 'col-sm-6' : 'col-sm-4'
    const addableClass = gridType == 'small' ? 'col-sm-4' : 'col-sm-6'

    this.setNeedIncrementResizeScale(gridType)

    for (let gridItem of gridItems) {
      $(gridItem).removeClass(removableClass)
                 .addClass(addableClass)
                 .addClass('animated fadeIn').one(animationEnd, function() {
                    $(gridItem).removeClass('animated fadeIn')
                  })
    }

    const gridFilters = document.getElementsByClassName('grid-filter-action')

    for (let gridFilter of gridFilters) {
      $(gridFilter).removeClass('active')
    }
    
    $(event).addClass('active')
    this.setHeightBasedOnInnerWidth(window.innerWidth)
  }

  recalculateOnResize() {
    $(window).on('resize', (e) => this.setHeightBasedOnInnerWidth(e.target.innerWidth))
  }

  recalculateOnInitialPageLoad() {
    this.setHeightBasedOnInnerWidth(window.innerWidth)
  }
}
