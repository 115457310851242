let navMinified = false;

function initNavMinfier() {
	$(document).on('scroll', () => resizeNav())
}

function resizeNav() {
  if ($(document).scrollTop() > 250 && !navMinified) {
    $("#navbar-logo").animate({ height: 45, width: 54 }, 75)
    $("body").animate("paddingTop", 60)
    $('#back-to-top').fadeIn()
    navMinified = true;
  } else if ($(document).scrollTop() <= 250 && navMinified) {
    $('#back-to-top').fadeOut()
    $("body").animate("paddingTop", 75)
    $("#navbar-logo").animate({ height: 100, width: 114 }, 75)
    navMinified = false
  }
}

function initScrollSpyAndAnimation() {
  $('body').scrollspy({ target: '#navbar', offset: 60 })

	$(".nav-link").on('click', (e) => {
    const hash = (e.target.hash || e.target.parentElement.hash)

    if (hash && hash.length && isRootPath) {
      e.preventDefault()

      $('html, body').animate({
        scrollTop: hash.includes('master')
          ? 0
          : $(hash).offset().top - 50
      }, 600);
    }
	})
}

function initNavTopScroll() {
	$('#back-to-top').click(() => $('body,html').animate({ scrollTop: 0 }, 400))
}

function mobileNavCollapse() {
  $('.navbar-nav > .nav-item > a').on('click', function() {
    $('.navbar-collapse').collapse('hide')
  })
}

export function initNavHelpers() {
  initNavMinfier()
  initNavTopScroll()
  initScrollSpyAndAnimation()
  mobileNavCollapse()
  resizeNav()
}
