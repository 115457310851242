import { initCounterInfo } from './counterInfo.js'
import { initNavHelpers } from './navigation.js'
import { initTinySlider } from './tinySlider.js'
import { initLightBox } from './lightBox.js'
import { ImageResizerAndGridFilter } from './imageResizer.js'
import { GridYearFilter } from './filters.js'

initLightBox()

$(document).on('turbolinks:load', () => {
	initCounterInfo()
	initTinySlider()
	initNavHelpers()
  
  const imageResizerAndGridFilter = new ImageResizerAndGridFilter()
  imageResizerAndGridFilter.recalculateOnInitialPageLoad()
  imageResizerAndGridFilter.recalculateOnResize()

  // For grid filtering (news and projects index views)
  window.imageResizerAndGridFilter = imageResizerAndGridFilter

  const gridYearFilter = new GridYearFilter()
  gridYearFilter.init()
})
