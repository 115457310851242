import { tns } from 'tiny-slider/src/tiny-slider'

let bannerTinySlider = null
let aboutTinySlider = null

export function initTinySlider() {
	$(document).ready(function() {
		if ($(".section-banner-tiny-slider").length) {
			bannerTinySlider = tns({
			  autoWidth: true,
			  gutter: 50,
			  mouseDrag: true,
			  container: ".section-banner-tiny-slider",
			  speed: 2000,
			  autoplay: true,
			  autoplayButtonOutput: false,
			  autoplayTimeout: 2000,
				slideBy: 1,
				mouseDrag: true,
				controls: false,
				nav: false,
				mouseDrag: true,
				autoplayHoverPause: true,
	      rewind: true,
        responsive: {
          320: {
            gutter: 15
          },
          900: {
            gutter: 50
          }
        }
			});
		} else {
      clearInitializedTinySlider(bannerTinySlider)
    }

    if ($(".about-gallery").length) {
			aboutTinySlider = tns({
        container: '.about-gallery',
        autoHeight: true,
        items: 1,
        swipeAngle: false,
        speed: 2000,
        autoplay: true,
        nav: false,
        autoplayButtonOutput: false,
        mouseDrag: true,
        controlsContainer: ".about-gallery-controls",
			});
		} else {
      clearInitializedTinySlider(aboutTinySlider)
    }
	})
}

function clearInitializedTinySlider(slider) {
  slider && slider.destroy && slider.destroy()
}
