const STEP_LENGTH = 5

export class GridYearFilter {
  constructor() {
    this.totalSteps = 0
    this.currentStep = 0
    this.leftActionVisible = false
    this.rightActionVisible = false
  }

  // On page load we count total amount of years rendered in HTML dom and get total steps
  setTotalSteps = () => this.totalSteps = Math.ceil($('.year-filter-item').length / STEP_LENGTH)

  setRightActionVisible = () => {
    this.totalSteps * 5 > STEP_LENGTH 
      ? this.toggleYearActionIcon('right', 'visible')
      : this.toggleYearActionIcon('right', 'hiden')
  }

  init() {
    this.setTotalSteps()
    this.setRightActionVisible()

    $('#filter-right').on('click', () => this.showNextItems('right'))

    $('#filter-left').on('click', () => this.showNextItems('left'))

    $('.btn-filter').on('click', (e) => {
      $('.btn-filter.active').removeClass('active')
      $(e.target).addClass('active')
    })
  }

  // We display STEP_LENGTH items
  // If we move step, then we hide prev block and show next
  showOrHideNextItems(action) {
    $('.year-filter-item').slice(
      this.currentStep * STEP_LENGTH,
      (this.currentStep * STEP_LENGTH) + STEP_LENGTH
    ).each(function(index) {
      action == 'show'
        ? $(this).removeClass('d-none').addClass('d-flex')
        : $(this).removeClass('d-flex').addClass('d-none')
    })
  }

  showNextItems(direction) {
    // hide next or prev years
    this.showOrHideNextItems('hide')
    // increment or decrement step based on direction
    this.currentStep += direction == 'right' ? 1 : -1
    // show next or prev years
    this.showOrHideNextItems('show')

    // If we have moved right and left action is not visible - show it
    if (this.currentStep > 0 && !this.leftActionVisible) {
      this.toggleYearActionIcon('left', 'visible')
    }

    // if we are at the begining and left action is visible - hide it
    if (this.currentStep == 0 && this.leftActionVisible) {
      this.toggleYearActionIcon('left', 'hiden')
    }

    // if we reached the end and right action is visible - hide it
    if (this.currentStep == (this.totalSteps - 1) && this.rightActionVisible) {
      this.toggleYearActionIcon('right', 'hiden')
    }

    // If we moved left from the end and right action is hiden - show it
    if (this.currentStep == (this.totalSteps - 2) && !this.rightActionVisible) {
      this.toggleYearActionIcon('right', 'visible')
    }
  }

  // Here we show or hide left/right action icon and set visibility boolean
  toggleYearActionIcon(direction, action) {
    if (action === 'hiden') {
      $(`#filter-${direction}`).removeClass('d-flex').addClass('d-none')
      this[`${direction}ActionVisible`] = false
    } else {
      $(`#filter-${direction}`).removeClass('d-none').addClass('d-flex')
      this[`${direction}ActionVisible`] = true
    } 
  }
}
