import './lib/statsCounter.js'  

export function initCounterInfo() {
	let countingProcessed = false
	$(document).on('scroll', function() {
		if (isVisible($('.section-contacts')) && !countingProcessed) {
			jQuery(function ($) {
				// custom formatting example
				$('.count-number').data('countToOptions', {
					formatter: function (value, options) {
						return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',')
					}
				})

				// start all the timers
				$('.timer').each(count);

				function count(options) {
					let $this = $(this)
					options = $.extend({}, options || {}, $this.data('countToOptions') || {})
					$this.countTo(options)
				}
				countingProcessed = true
			});
		}
	})
}

function isVisible($el) {
	if (!$el.length) return

  let elementTop = $el.offset().top
  let elementBottom = elementTop + $el.outerHeight()
  let viewportTop = $(window).scrollTop()
  let viewportBottom = viewportTop + $(window).height()
  return elementBottom > viewportTop && elementTop < viewportBottom
}
